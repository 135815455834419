@import "../../../../../styles/functions";

.blurred-background{
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    background-color:  rgba(0, 0, 0, 0.70);
    z-index: 32;
    transition: all 0.3s ease;

    .side-panel{
        position: absolute;
        right: 0px;
        width: 496px;
        height: 100%;
        background: #ffffff;
        overflow-y: scroll;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;

        .header-container{
            display: flex;
            justify-content: space-between;
            padding: pxToRem(24);
            border-bottom: solid 1px #E1E1E1;

            .heading{
                color: #41434C;
                font-family: 'Inter';
                font-size: pxToRem(24);
                font-weight: 700;
                margin: 0;
            }

            .close-btn-container{
                width: pxToRem(24);
                height: pxToRem(24);
                cursor: pointer;
            }
        }

        .product-list-container{
            padding: pxToRem(24);
            height: 85%;

            .inner-container{
                border: 1px solid #E0E0E0;
                height: 100%;
                overflow-y: scroll;
                overflow-x: hidden;
                border-radius: 4px 4px 0px 0px;

                .header{
                    display: grid;
                    grid-template-columns: 4fr 1fr;
                    padding: pxToRem(15);
                    background:  #F2F2F2;
                    border-bottom: 1px solid #E1E1E1;

                    p{
                        color:#4F4F4F;
                        font-family: 'Inter';
                        font-size: pxToRem(12);
                        font-weight: 500;
                        line-height: 140%;
                        margin: 0;
                    }
                }
            }
        }
    }
}