@import "../../../styles//functions";

.card {
  background: #ffffff;
  border: 0.2rem solid #2e31be;
  box-shadow: 0 pxToRem(2.56) pxToRem(12.8) rgba(80, 80, 80, 0.1);
  border-radius: pxToRem(8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: pxToRem(248);
  padding: 0 pxToRem(24);
  position: relative;

  .card_heading {
    font-style: normal;
    font-weight: 600;
    font-size: pxToRem(18);
    line-height: 130%;
    color:#41434c;
    margin-top: pxToRem(32);
    margin-bottom: pxToRem(8);
    text-transform: uppercase;
    text-align: center;
  }

  .card_tagline {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(12);
    line-height: 160%;
    color: #7a7a7a;
    margin-bottom: pxToRem(12);
    text-align: center;
  }


  .card_text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(11);
    line-height: 140%;
    color: #5c5c5c;
    text-align: center;

    .card_pricing {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: pxToRem(24);
      line-height: 120%;
      color: #41434c;
      text-align: center;
    }
  }

  .card_details_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(10);
    margin: pxToRem(24) 0;

    .mt_10 {
      margin-top: pxToRem(10);
    }
  }

  .card_button_wrapper {
    margin-bottom: pxToRem(24);

    .card_button {
      background: #2e31be !important;
      border-radius: 0.4rem !important;
    }
  }

}