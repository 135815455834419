@import "../../../../../styles/functions";

.settings {
  width: 40%;
  margin: pxToRem(24) pxToRem(24) 0 0;
  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: pxToRem(16);
    line-height: 140%;
    color: #333333;
  }
  .settings-item {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 15px 0;
  }
  .button {
    margin: pxToRem(10) 0 pxToRem(16) 0;
    width: pxToRem(100);
  }
}
