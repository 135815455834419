@import "/src/styles/functions";

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: pxToRem(44);
    height: 90vh;

    .heading {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: pxToRem(24);
        line-height: 120%;
        color: #41434c;
        margin: pxToRem(32) 0 pxToRem(8) 0;
    }

    .description {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: pxToRem(12);
        line-height: 160%;
        color: #41434c;
        margin-bottom: pxToRem(44);
    }

    .card_list {
        display: flex;
        gap: pxToRem(32);
    }
}
