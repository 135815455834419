@import "../../../../../styles/functions";

.container{
    margin: pxToRem(14) 0;

    // .dropdown-class > div > div > div:nth-child(2){
    //     top: -10px !important
    // }
    
    .side-panel-toggle{
        margin: pxToRem(10) 0;
        span {
            cursor: pointer;
            color: #2E31BE;
            font-family: 'Inter';
            font-size: pxToRem(12);
            font-weight: 500;
            line-height: pxToRem(16); 
        }
    }
}