@import "../../styles//functions";

.container{
    padding: pxToRem(24);
    .header-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .page-header{
            .heading{
                color:#41434C;
                font-family: 'Inter';
                font-size: pxToRem(20);
                font-weight: 600;
                line-height: pxToRem(24); 
                margin: 0 0 pxToRem(8) 0;
            }
    
            .desc{
                color: rgba(102, 102, 102, 0.80);
                font-family: 'Inter';
                font-size: pxToRem(14);
                font-weight: 400;
                line-height: pxToRem(17);
                margin: 0;
            }
        }

        .dropdown{
            width: pxToRem(150);

            .dropdownClass > div > div > div:nth-child(2){
               top: pxToRem(18) !important
            }
        }
    }
}