@import "./styles/functions";
@import './styles//mixins';

html,
body {
	padding: 0;
	margin: 0;
	min-height: 100vh;
	font-size: 10px;
}

* {
	font-family: "Inter"; 
}

$rules: (
	(m, margin),
	(ml, margin-left),
	(mt, margin-top),
	(mb, margin-bottom),
	(mr, margin-right),
	(p, padding),
	(pl, padding-left),
	(pt, padding-top),
	(pr, padding-right),
	(pb, padding-bottom)
);

@each $item in $rules {
	@include generate(nth($item, 1), nth($item, 2));
}


// Overriding nitrozen react classes

// Search input box style overrides
.n-input-container{
	border-radius: 0.4rem !important;
	background: #FFFFFF !important;
	border: 1px solid #E4E5E6 !important;
	input::placeholder:hover{
		font-weight: 400 !important;
	}
}

.n-focused-border{
	border: 1px solid #E4E5E6 !important;
}

.n-checkbox-container {
	display: block !important;
	font-family: "Inter" !important;
	font-style: normal;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 140% !important;
	color: #828282 !important;
	padding: 0px 0px 0px 26px !important;
}

.n-checkbox {
	height: 18px !important;
	width: 18px !important;
	border-radius: 3px !important;
}
  
.n-checkbox:after {
	left: 6.06px !important;
	top: 2px !important;
	width: 3.11px !important;
	height: 8.61px !important;
}

.n-button-primary{
	background-color:#2E31BE !important ;
}

.n-button-rounded{
	border-radius:4px !important;
}

.n-button-mid{
	height: 4rem !important;
}

.n-input::placeholder{
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 2.6rem;
	color: #9B9B9B;
}

.n-focused-border{
	border: 0.1rem solid #000093 !important;
}

// Pagination dropdown styles overrides
.n-select__trigger{
	// padding: 0.8rem 0.8rem 0.8rem 1.2rem !important;
	border-radius: 0.4rem !important;
	border: 1px solid #E0E0E0 !important;
}

.n-dropdown-arrow{
	color: rgba(0, 0, 0, 0.54) !important;
}

.n-options{
	border-radius: 0.4rem !important;
	top: -155px !important
}

// Toggle button classes override
.n-toggle-container{
	padding-right: 0 !important;
}

.n-toggle-container .n-switch.medium {
	width: 36px !important;
	height: 16px !important;
}

.n-toggle-container .n-switch .n-slider{
	border: none !important;
	background-color: #666666  !important;
	border-radius: 3rem;
}

.n-toggle-container .n-switch.medium .slider-ball {
	height: 2rem !important;
	width: 2rem !important;
	background-color: #FFFFFF !important;
	right: 100% !important;
}

.n-toggle-container .n-switch .n-slider.checked {
	background-color: #a9aaea !important;	
}

.n-toggle-container .n-switch .slider-ball{
	right: 100% !important;
}

.n-toggle-container .n-switch .n-slider.checked .slider-ball{
	right: 0% !important;
	background: #2E31BE !important;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.237602) !important;
}

.n-toggle-container .n-switch.small{
	width: 36px !important;
	height: 16px !important;
}

.n-toggle-container .n-switch.small .n-slider.checked{
	background-color: #D6D8F5 !important;
}
.n-toggle-container .n-switch.small .slider-ball{
	height: 2rem !important;
	width: 2rem !important;
	background-color: #FFFFFF !important;
}

.n-toggle-container .n-switch .n-slider.checked .slider-ball{
	width: 2rem !important;
	height: 2rem !important;
}

.n-checkbox-container input:checked ~ .n-checkbox {
	background-color: #2E31BE !important;
}

/*Text Area*/

.heading-context{
	margin-top: pxToRem(16);
	
	.n-input-grp {
		width: 50%;
		position: relative;
	}
	  
	.n-input-underinfo {
		display: none !important;
	}
	  
	.n-input-label{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 140%;
		color: #828282;
	  }
	  
	.n-input-textarea {
		padding: pxToRem(0) !important;
		font-size: pxToRem(12) !important;
		height: pxToRem(65) !important;
	}
	  
	textarea::placeholder {
		font-size: pxToRem(12) !important;
	}
	  
	.n-form-input {
		flex-direction: row !important;
		justify-content: space-between !important;
	}
	  
	.n-input-label-container {
		margin-left: pxToRem(0) !important;
		width: 50% ;
	}
	  
	.n-input-container {
		width: 100%;
		margin-bottom: pxToRem(10) !important;
		border: pxToRem(1) solid #e0e0e0 !important;
		border-radius: pxToRem(4) !important;
		padding: pxToRem(8) pxToRem(12) pxToRem(8) pxToRem(12) !important;
	}
	  
	.n-input-maxLength {
		position: absolute !important;
		right: pxToRem(-12) !important;
		bottom: pxToRem(-6) !important;
	  
		font-family: "Inter" !important;
		font-style: normal !important;
		font-weight: 400 !important;
		font-size: pxToRem(10) !important;
		line-height: pxToRem(16) !important;
		color: #9b9b9b !important;
	}
}

.n-input-label{
	color:  #9B9B9B !important;
	font-size: 12px !important;
	font-family: 'Inter' !important;
	font-weight: 300 !important;
	line-height: 160% !important;
}

.n-input-label-container{
	margin-left: 0 !important;
}

.n-input-maxLength{
	margin-right: 0 !important;
}

.n-dropdown-label{
	margin-left: 0 !important;
}

// .n-dropdown-container .n-select__trigger span{
// 	max-width: 200px !important;
// }

// TOAST STYLES
.n-toast-title-container{
	justify-content: center !important;
	align-items: center !important;
}

.n-toast-body {
	display: none !important;
}

.n-toast-header {
	text-align: center !important;
}

.n-toast-wrapper{
	border-radius: 4px !important;
	// width: 30rem !important;
}

