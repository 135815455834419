@import "../../../../../../../../../../styles/functions";

.body {
  display: flex;
  margin-top: pxToRem(16);
  gap: 20px;
  .themes-option {
    label {
      font-family: "Inter" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: pxToRem(14) !important;
      padding-left: pxToRem(24) !important;
      line-height: 150% !important;
      color: #41434c !important;
    }

    input[type="radio"]:not(:checked) + label:before,
    input[type="radio"]:checked + label:before {
      width: pxToRem(16) !important;
      height: pxToRem(16) !important;
      transition: none !important;
      left: 0 !important;
      top: 50% !important;
      transform: translate(0, -50%);
    }

    // input[type="radio"] {
    //   background: #3535f3 !important;
    //   border: 3px solid #3535f3 !important;
    // }

    // input[type="radio"]:checked + label:before {
    //   box-shadow: inset 0 0 0 1px var(--ColorPrimary50, #3535f3) !important;
    // }
  }
}
