@import "../../../../../styles/functions";


.container{
    margin: pxToRem(14) 0;
    .grid-of-two{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: pxToRem(16);
        margin-bottom: pxToRem(12);
    }
}