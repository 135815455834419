@import "../../../../../styles//functions";

.table-container{
    border:1px solid #E0E0E0;
    border-radius: pxToRem(4);
    .table-header{
        display: grid;
        grid-template-columns: auto 5fr 2fr 2fr 1fr;
        padding: pxToRem(16);
        background: #F5F5F5;

        div{
            color:  #41434C;
            font-family: 'Inter';
            font-size: pxToRem(14);
            font-weight: 600;
            line-height: 130%;
        }

        .checkbox-container{
            margin-right: pxToRem(24);
        }
    }
}