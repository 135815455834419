@import "../../../../../styles//functions";

.container{
    
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: pxToRem(24);
        .title{
            span{
                color: #41434C;
                font-family: 'Inter';
                font-size: pxToRem(18);
                font-weight: 600;
                line-height: pxToRem(24); 
            }
        }

        .btn-container-dropdown{
            display: flex;

            .dropdown-container{
                margin-right: pxToRem(16);
                width: pxToRem(150);

                .dropdownClass > div > div > div:nth-child(2){
                    top: pxToRem(18) !important
                }
            }

            .btn-container{
                cursor: pointer;
            }
        }
    }

}