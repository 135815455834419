@import "../../styles/functions";

.customize-pops {
  padding: 2.4rem;
  .header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(20);
    line-height: 140%;
    color: #41434c;
  }

  .categories {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin: pxToRem(14) 0;
  }

  .horizontal-line {
    height: pxToRem(1);
    margin: pxToRem(16) 0;
    background: #e0e0e0;
  }
}
