@import "/src/styles/functions";

.preview {
  border: 1px solid #E0E0E0;
  border-radius: pxToRem(8);
  height: pxToRem(600);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: sticky;
  top: pxToRem(10);

  .preview_header {
    width: 100%;
    height: pxToRem(40);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 0 15px;
    border-radius: pxToRem(8);
    box-sizing: border-box;
    
    .flex {
      display: flex;

      .preview_icon {
        align-self: center;
        cursor: pointer;

        &.phone_icon {
          padding: 3px 6px;
        }

        &.laptop_icon {
          padding: 5px 6px;
          margin-right: 4px;
        }

        &.selected_icon {
          background-color: #f2f2f2;
        }
      }
    }

    .preview_heading {
      font-weight: 500;
      font-size: pxToRem(12);
      line-height: pxToRem(16);
      color: #7D7676;
      font-family: 'Inter';
    }


  }

  .main_wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    align-items: stretch;
    justify-items: stretch;

    &.laptop {
        width: 100%;
    }

    &.phone {
        width: 375px;
        overflow: hidden;
        background-color: #ffffff;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
    }
  }

  .image img {
    width: 100%;
  }

  .iframe {
    all: unset;
    display: block;
    background-color: #E0E0E0;
    border-top: 1px solid #E0E0E0;
  }

}