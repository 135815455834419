@import "../../../../../styles/functions";

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(14);
    line-height: 160%;
    color: #3c3131;
  }
  .description {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(12);
    line-height: 130%;
    color: #666666;
    margin-top: pxToRem(4);
  }
}
