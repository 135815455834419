@import "../../../../../../../../../../styles/functions";

.body {
  margin: pxToRem(16) 0;
  display: flex;
  flex-direction: row;
  gap: pxToRem(16);
  label {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: pxToRem(16) !important;
    line-height: 140% !important;
    color: #41434c !important;
    justify-content: center !important;
    span {
      // height: pxToRem(20) !important;
      // width: pxToRem(20) !important;
      top: 50% !important;
      transform: translate(0, -50%) !important;
    }
  }
}
