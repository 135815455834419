@import "../../../../../styles//functions";


.outer-circle{
    width: pxToRem(36);
    height: pxToRem(36);
    border-radius: 50%;
    padding: pxToRem(3);
    margin-right: pxToRem(4);
    cursor: pointer;

    .inner-circle{
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }
}