@import '../../../../styles/functions';

.home-page-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span{
        color: #41434c;
        font-family: Inter;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .toggle-button-container{
        display: flex;

        .toggle-text{
            color: #2E31BE;
            font-family: 'Inter';
            font-size: 12px;
            font-weight: 700;
            margin-right: pxToRem(8);
        }
    }
}

