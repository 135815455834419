@import "../../../../../../../../../../styles/functions";

.timing-body-item {
  .header {
    display: flex;
    align-items: center;
    .tooltip {
      margin-left: pxToRem(10);
    }

    .title {
      font-family: "Inter";
      font-style: normal;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 160%;
      color: #3c3131;
    }
  }
}
