@import "../../../../../styles/functions";


.container{
    width: 40%;
    margin-right: pxToRem(24);

    .title{
        color: #333;
        font-size: pxToRem(16);
        font-family: "Inter";
        font-weight: 600;
        line-height: 140%;
        margin: 0 0 pxToRem(14) 0;
    }

    .button {
        width: pxToRem(100);
      }
}