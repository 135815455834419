@import "../../styles/functions";

.config-page-container {
  padding: pxToRem(24);

  .config-page-body{
    margin-top: pxToRem(24);
    display: flex;
  }
}
