@import "../../../../../styles//functions";

.container{
    margin: pxToRem(14) 0;

    .title{
        color:#41434C;
        font-size: pxToRem(13);
        font-family: 'Inter';
        font-weight: 400;
        line-height: 140%;
        margin: 0;
    }

    .subheading{
        color: rgba(102, 102, 102, 0.80);
        font-size: pxToRem(12);
        font-family: 'Inter';
        font-weight: 400;
        line-height: 130%;
        margin: pxToRem(4) 0 pxToRem(12) 0;
    }
}