@import "../../../../../styles/functions";

.container{
    margin: pxToRem(14) 0;
    .title{
        color: #41434C;
        font-family: 'Inter';
        font-size: pxToRem(13);
        font-weight: 400;
        line-height: 140%;
        margin: 0;
    }

    .desc{
        color: rgba(102, 102, 102, 0.80);
        font-family: 'Inter';
        font-size: pxToRem(12);
        font-weight: 400;
        line-height: 130%;
        margin: pxToRem(4) 0 pxToRem(12) 0;
    }

    .dropdown-container{

        .dropdown-class > div > div > div:nth-child(2) {
            top: pxToRem(18) !important
        }
    }
}