@import "../../../../../styles//functions";

.container{
    display: grid;
    grid-template-columns: auto 5fr 2fr 2fr 1fr;
    padding: pxToRem(16);
    align-content: center;
    border-top: 1px solid #E0E0E0;

    .checkbox-container{
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-10px);
        margin-right: pxToRem(24);
    }

    .nudge-ui-container{
        display: flex;

        .icon-img-container{
            width: 64px;
            height: 64px;   
            object-fit: contain;
            margin-right: pxToRem(16);

            img{
                width: 100%;
                height: 100%;
                border-radius: 4px;
            }
        }

        .nudge-text-container{
            p{
                margin: 0;
                color: #41434C;
                font-family: 'Inter';
                font-size: pxToRem(14);
                font-weight: 400;
                line-height: 130%;
                width: 90%;
            }
        }
    }

    .action-container{
        display: flex;
        align-items: center;
        .delete-btn-container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: pxToRem(29);
            height: pxToRem(29);
            border: solid 1px #8F8F8F;
            border-radius: pxToRem(5);
            cursor: pointer;
    
            .img{
                width: pxToRem(20);
                height: pxToRem(20);
            }
        }
    }

    .column-text{
        color: #5C5C5C;
        font-family: 'Inter';
        font-size: pxToRem(14);
        font-weight: 400;
        line-height: 150%;
        display: flex;
        align-items: center;
    }

    .capitalize-text{
        text-transform: capitalize;
    }
}

