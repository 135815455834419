@import "../../../../../styles/functions";

.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading-text{
        .heading{
            font-size: pxToRem(20);
            font-family: 'Inter';
            font-weight: 600;
            line-height: pxToRem(24);
            color: #41434C;
            margin: 0 0 pxToRem(8) 0;
        }

        .description{
            color: rgba(102, 102, 102, 0.80);
            font-size: pxToRem(14);
            font-family: 'Inter';
            line-height: pxToRem(17);
            margin: 0;
        }
    }

    .toggle-btn-container{

        display: flex;
        justify-content: center;
        align-items: center;

        .toggle-text{
            color: #2E31BE;
            font-size: pxToRem(12);
            font-family: 'Inter';
            font-weight: 700;
            line-height: pxToRem(16);
            margin-right: pxToRem(8);
        }
    }
}