@import "../../../../styles/functions";

.pop-Category {
  .category-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .header {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: pxToRem(16);
      line-height: 140%;
      color: #000000;
    }

    .description {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: pxToRem(12);
      line-height: pxToRem(20);
      color: #9b9b9b;
    }
  }

  .categories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: pxToRem(20);
    margin-top: pxToRem(14);
  }
}
