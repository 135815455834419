@import "../../../../../styles//functions";


.min-input-container{
    .title-container{
        display: flex;
        margin-bottom: pxToRem(5);

        .label{
            color:  #9B9B9B;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;
            line-height: 160%; 
            margin-right: pxToRem(8);
        }
    }
}