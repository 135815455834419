@import "../../../../../styles//functions";

.container{
    padding: pxToRem(16);
    border-radius: 4px;
    border: 1px solid  #EDECE9;

    .label{
        color: #9B9B9B;
        font-family: 'Inter';
        font-size: pxToRem(14);
        font-weight: 400;
        line-height: 150%;
        margin: 0 0 pxToRem(4) 0;
    }

    .stats-wrapper{
        .value{
            color: #41434C;
            font-family: 'Inter';
            font-size: pxToRem(16);
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.08px;
            margin-right: pxToRem(8);
        }

        .growth-icon{
            margin-right: pxToRem(4);

            .img{
                width: pxToRem(12);
                height: pxToRem(12);
                object-fit: contain;
            }
        }

        .growth-percentage{
            font-family: 'Inter';
            font-size: pxToRem(14);
            font-weight: 400;
            line-height: 150%;
        }

        .increase{
            color: #0A5F23;
        }

        .decrease{
            color: #C13C3C;
        }
    }
}