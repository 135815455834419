@import "../../styles/functions";

.general-settings {
  padding: pxToRem(24);
  .header {
    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: pxToRem(20);
      line-height: 24px;
      color: #41434c;
    }
    .description {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: pxToRem(14);
      line-height: pxToRem(17);
      color: rgba(102, 102, 102, 0.8);
      margin-top: pxToRem(4);
    }
  }

  .settings {
    width: 100%;
    display: flex;
    margin-top: pxToRem(24);
  }
}
