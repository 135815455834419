@import "../../../../styles/functions";

.card {
  border: pxToRem(1) solid #e4e5e6;
  border-radius: pxToRem(12);
  padding: pxToRem(24);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.card:hover {
  box-shadow: 5px 5px 5px 0 #0000001f;
  -moz-box-shadow: 5px 5px 5px 0 #0000001f;
  -webkit-box-shadow: 5px 5px 5px 0 #0000001f;
}

.card-logo-style {
  height: pxToRem(40);
}

.card-logo-style img {
  height: pxToRem(40);
}
.card-content {
  display: flex;
  flex-direction: column;
  margin-top: pxToRem(24);
  align-items: flex-start;
  flex-grow: 1;
}

.card-content h4 {
  margin: 0;
  margin-bottom: pxToRem(5);
  font-weight: 500;
  font-size: pxToRem(16);
  color: #41434c;
}

.card-content p {
  margin: 0;
  font-size: pxToRem(12);
  line-height:160%;
  color: #828282;
  font-weight: 300;
  font-family: "Inter", sans-serif;
}

.card-footer {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: pxToRem(30);
  font-family: "Inter";
  .active {
    border: 1px solid #27ae60;
    border-radius: 2px;
    color: #27ae60;
    padding: 2px 12px;
  }
  .inactive {
    border: 1px solid #ae2727;
    border-radius: 2px;
    color: #ae2727;
    padding: 2px 12px;
  }
}

.arrow-div {
  width: 100%;
}

.arrow {
  position: relative;
  border-radius: pxToRem(3);
  border: 1px solid #8f8f8f;
  box-sizing: border-box;
  color: blue;
  width: pxToRem(30);
  height: pxToRem(30);
  cursor: pointer;
}

.arrow svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: pxToRem(14);
  height: auto;
}
