@import "../../../../../styles/functions";

.container{
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding: pxToRem(15);
    background:  #fff;
    border-bottom: 1px solid #E1E1E1;

    .product-detail-container{
        display: flex;
        align-items: center;
        overflow: hidden;

        img{
            width: pxToRem(32);
            height: pxToRem(32);
            border-radius: pxToRem(4);
            margin-right: pxToRem(8);
            object-fit: contain;
        }

        p{
            color: #41434C;
            font-family: 'Inter';
            font-size: pxToRem(14);
            font-weight: 400;
            line-height: 150%;
            margin: 0;
            text-overflow: ellipsis;
        }
    }

    .delete-btn-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: pxToRem(29);
        height: pxToRem(29);
        border: solid 2px #8F8F8F;
        border-radius: pxToRem(5);
        cursor: pointer;

        .img{
            width: pxToRem(20);
            height: pxToRem(20);
        }
    }
}