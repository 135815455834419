.paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    padding: 1.2rem;
    user-select: none;
    margin-top: pxToRem(8);
}
 
.text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 21px;
    color: #828282;
}

.rows-per-page-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 21px;
    text-align: center;
    color: #828282;
}

.dropdown {
    width: 7.2rem;
}

.controls {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.button {
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
}

.buttonDisabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: not-allowed;
}
