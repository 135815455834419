@import "../../../../../styles/functions";

.container{
    display: inline-block;
    width: 100%;
    margin-bottom: pxToRem(10);
    .error-wrapper{
        display: flex;
        color:  #CD0909;
        font-size: pxToRem(12);
        font-family: 'Inter';
        font-weight: 400;
        line-height: pxToRem(17);
        margin-top: pxToRem(4);
        .error-icon{
            margin-right: pxToRem(7);
        }
    }
}