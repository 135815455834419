@import "../../../../../styles//functions";

.empty_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  font-weight: 600;
  font-size: pxToRem(20);
  line-height: 140%;
  color: #4f4f4f;
}
