@import "../../../../../styles/functions";

.dialog-backdrop{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .dialog-body{
        font-family: "Inter";
        background: #ffffff;
        border: 0.1rem solid  #e0e0e0;
        border-radius: pxToRem(12);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: pxToRem(24);
        
        img{
            width: pxToRem(50);
            height: pxToRem(50);
        }

        .heading{
            color:  #41434C;
            text-align: center;
            font-family: 'Inter';
            font-size: pxToRem(18);
            font-weight: 600;
            line-height: 130%;
            margin: pxToRem(16) 0 0 0;
        }

        .subheading{
            color: #5C5C5C;
            text-align: center;
            font-family: 'Inter';
            font-size: pxToRem(12);
            font-weight: 400;
            line-height: 160%;
            margin: pxToRem(12) 0 0 0;
        }

        .btn-container{
            margin-top:pxToRem(32);
            width: 100%;
            display: flex;
            justify-content: space-evenly;

            button{
                margin-right: pxToRem(10);
            }
        }
    }
}