@import "../../../styles/functions";

datalist {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  option {
    padding: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 140%;
    color: #41434c;
  }
}

.slide-range {
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 500px;
  height: 2.4rem;
  width: 100%;

  input[type="range"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    /*  overflow: hidden;  remove this line*/

    /* New additions */
    height: 0.4rem;
    background: #e7eeff;
  }

  /* Thumb: webkit */
  input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 1.6rem;
    width: 1.6rem;
    background-color: #2e31be;
    border-radius: 50%;

    /* box-shadow: -407px 0 0 400px #f50; emove this line */
    transition: 0.2s ease-in-out;
  }

  /* Thumb: Firefox */
  input[type="range"]::-moz-range-thumb {
    height: 1.6rem;
    width: 1.6rem;
    background-color: #2e31be;
    border-radius: 50%;
    border: none;

    /* box-shadow: -407px 0 0 400px #f50; emove this line */
    transition: 0.2s ease-in-out;
  }
}
