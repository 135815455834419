@import "./../../styles/functions";

.features-page {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-color: #f9f9f9;
}

.features-page-left {
  display: flex;
  flex-direction: column;
  width: 75%;
  border-radius: pxToRem(12);
  margin-right: pxToRem(24);
  padding: pxToRem(24);
  background-color: #fff;
}

.features-page-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: pxToRem(16);
  margin-top: pxToRem(24);
}
